import axios from "axios";
import { binaryToString } from "@/plugins/s";

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = `/api`;
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    if (window.app.$storage.has("auth.token")) {
      config.headers.Authorization = window.app.$storage.get("auth.token");
    }
    if (window.app.$r.lang) {
      config.headers.lang = window.app.$r.lang;
    }
    if (window.app.$r.store.currency) {
      config.headers.currency = window.app.$r.store.currency;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    let s = "te" + "xt" + "/" + "se";
    s += "cu";
    s += "re";
    let c =
      response.headers["content-type"] || response.headers["Content-Type"];
    if (c && c.search(s) >= 0) {
      response.data = JSON.parse(binaryToString(response.data));
    }
    if (response.data) {
      response.data = window.app.$helper.htmlDecode(response.data);
      if (response.data.msg) {
        window.app.$toast(window.app.$t(response.data.msg));
      }
    }
    return response;
  },
  (error) => {
    const res = error.response;
    if (res && res.status === 307 && res.data.location) {
      console.log("redirect to:" + res.data.location);
      window.location.replace(res.data.location);
    }

    if (res && res.data.msg === "auth.failed") {
      window.app.$toast(window.app.$t("auth.failed"), {
        type: "error",
      });
      window.app.$r.store.user = { login: false, info: {} };
      window.app.$storage.remove("auth.token");
      window.app.$storage.remove("user_login");
    } else if (res && res.data.msg) {
      window.app.$toast(window.app.$t(res.data.msg), {
        type: "error",
      });
    }

    return Promise.reject(error);
  }
);
export default {
  install: (app) => {
    app.config.globalProperties.$axios = axios;
  },
};
