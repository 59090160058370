function text_to_int(v) {
  function hex2bin(hex) {
    return ("00000000" + parseInt(hex, 16).toString(2)).substr(-8);
  }

  let arr = v.match(/.{1,2}/g);
  let r = "";
  arr.forEach((item) => {
    r += hex2bin(item);
  });
  return r;
}

export function binaryToString(binary) {
  binary =
    binary.slice(binary.length - 19, binary.length) +
    binary.slice(0, binary.length - 19);
  binary = text_to_int(binary);

  let arr = binary.match(/.{1,8}/g);
  let r = "";
  arr.forEach((v) => {
    r += String.fromCharCode(parseInt(v, 2));
  });
  return r;
}
